//	TITELS
.date--agenda{
  @include title-font(bold);
  @include title-font-size(14);
  color: $color-black;
  line-height: 1em;
  margin-bottom: .2em;
  text-transform: uppercase;

}

h1, h2, h3, h4, h5{
  @include title-font(regular);
  color: $color-accent-2;
  line-height: 1em;
  margin-bottom: .2em;
  text-transform: uppercase;
  a{
    text-decoration: none;
  }
}
h1{
  @include title-font(bold);
  @include title-font-size(24);
  margin-bottom: $margin-bottom;
}
h2{
  @include title-font-size(16);
}
h3{
  @include title-font(bold);
  @include font-size(24);
  .item &{
    color: $color-accent;
  }
}
h4, h5, h6{
  @include font-size(18);
  @include title-font(bold);
  .item & {
    color: $color-accent;
  }

  .footer & {
    @include font-size(17);
    // @include text-overflow();
    @include title-font(regular);
    // border-bottom: 1px solid darken($color-grey-lighter, 10);
    letter-spacing: .05em;
    margin: 0 10px 10px 0;

    @include mq($bp-smalltablet, true){
      @include font-size(18);
      margin-right: 20px;
    }

    a {
      color: $color-grey;

      &:hover, &:active {
        color: $color-accent-2;
      }
    }
  }

  .footer .newsletter & {
    color: $color-grey;
    border: 0;
  }
}

//	PARAGRAAF
p{
  margin: 0 0 $margin-bottom;
  .item__content &{
    @include font-size(12);
    line-height: 1.4em;
  }
}
.paragraph--address{
  display: inline-block;
  @include mq($bp-desktop, true){
    float: right; display: inline;
    width: 50%;
  }
}

.intro{
  @include base-font;
  @include base-font-size(14);
  color: $color-grey-dark;
}
sub{ font-size: 80%; position: relative; top: 3px; }
sup{ font-size: 80%; position: relative; top: -3px;}

.item__content--image .text-box,
.item__content--case .text-box{
  h4{
    padding: 7px 10px 5px;
    margin: 0;
    color: $color-white;
    @include font-size(24);
    background: $color-accent;
  }
  p{
    background: $color-white;
    color: $color-grey;
    padding: 7px 10px;
    margin: 0;
    @include font-size(13);
  }
  a{
    position: relative;
    display: block;
    padding-right: 30px;
    line-height: 1.4em;
    text-decoration: none;
    color: $color-grey;
    &:hover, &:active{
      color: $color-accent-2;
    }
    span{
      position: absolute; right: 0; top: 50%;
      margin-top: -11px;
      // @include sprite(pijl);
    }
  }
}

//	OPSOMMINGEN
.list{
  margin: 0 0 $margin-bottom 24px;
  li{
    color: $color-accent-2;
    .item__content &{
      @include font-size(12);
      line-height: 1.4em;
    }
    span{
      color: $color-text;
    }
  }
  &.articles, &.calendar{
    list-style: none;
    margin: 0 0 $margin-bottom;
    //border-top: 1px solid $color-grey-lighter;
    line-height: $font-lineheight-alt;
    li{
      //border-bottom: 1px solid $color-grey-lighter;
      a{
        display: block; padding: 0 0 5px 0;
        text-decoration: none;
        span.titel{
          color: $color-accent;
          display: block;
          //margin-bottom: 5px;
        }
        span.date, span.location{
          display: block;
          color: $color-grey;
          @include font-size(12);
          .item__content &{
            @include font-size(11);
            //line-height: 1.4em;
          }
        }
        &:hover span.titel{
          color: $color-hover;
        }
      }
    }
  }
}

//	LINKS
a{
  color: $color-accent-2;
  outline: 0;
  border: 0;
  &:hover{
    color: $color-accent;
  }
}
a.search-result__hit{
  text-decoration: none;
  background: $color-status-info;
  padding: 3px;
}

// BUTTON
a.btn, button{
  position: relative; top: $margin-bottom / 2;
  background: $color-accent; display: inline-block;
  margin-bottom: $margin-bottom;
  padding: 3px 10px 2px;
  @include font-size(16);
  line-height: 20px;
  @include title-font();
  color: $color-white;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  //@include transition(all, .2, ease);
  &:hover{
    background: $color-hover;
  }
  @include mq($bp-smalltablet, true) {
    @include font-size(18);
    padding: 6px 10px 4px;
  }
}

// .item__content--link{
//   @include mq($bp-mobile, true){
//     padding-bottom: 22px;
//   }
// }

// .item__content p a{
//   position: relative;
//   padding-right: 30px;
//   margin: 0;
//   height: 32px;
//   overflow: hidden;
//   line-height: 16px;
//   //background: yellow;
//   display: block;
//   text-decoration: none;
//   //border: 1px solid red;
//   //@include mq($bp-mobile, true){
//     position: absolute; bottom: 5px; left: 10px; right: 10px; z-index: 2;
//     margin: 0;
//   //}

//   &:hover, &:active, .item__content--link:hover &{
//     color: $color-accent-2;
//   }
//   table, table tr td{
//     height: 32px;
//     vertical-align: middle;
//     padding: 0; margin: 0;
//   }
//   span{
//     position: absolute; right: 0; top: 50%;
//     // @include sprite(pijl);
//     margin-top: -11px;
//   }
// }

// .item__content--image p{
//   margin-bottom: 40px;

//   @include mq($bp-mobile, true) {
//     margin-bottom: 20px;
//   }
// }

// .item__content--image p a {
//   bottom: 0;
//   left: 0;
//   right: 0;
//   padding: 5px 40px 5px 10px;
//   @include font-size(13);
//   line-height: 1.3em;
//   text-decoration: none;
//   color: $color-grey;
//   &:hover, &:active{
//     color: $color-accent-2;
//   }
//   span{
//     right: 10px;
//   }
// }

.read-more {
  @include text-overflow();
  display: block;
  height: 21px;
  line-height: 21px;
  padding-right: 30px;
  position: relative;
}

//	AFBEELDINGEN
figure{
  display: block;
  margin: 0 auto $margin-bottom;
  max-width: 100%;
  width: 100%;

  .item__content--image &{
    position: relative;
  }

  @include mq($bp-smalltablet, true) {
    &.left {
      // display: inline;
      float: left;
      margin: 0 2% $margin-bottom 0;
      max-width: 49%;
      width: inherit;
    }

    &.right {
      // display: inline;
      float: right;
      margin: 0 0 $margin-bottom 2%;
      max-width: 49%;
      width: inherit;
    }

    &.no-margin {
      margin: 0 0 $margin-bottom;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  figcaption {
    @include base-font();
    @include base-font-size(11);
    color: lighten($color-text, 25);
    display: block;
    margin: 6px auto 0;
    text-align: center;
  }
}

figure.logo3 {
  display: inline;
  float: left;
  margin-right: 5%;
  width: 30%;

  &.logo3--last {
    margin-right: 0;
  }

  &.logo3--clear {
    //clear: both;
  }

  img {
    width: 100%;
  }
}

.item__content {

  a {
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
    padding: 0 20px 20px;
    // border-left: 5px solid $color-accent;

    a {
      color: $color-text;
    }
  }

  figure {
    margin: 0;

    h2 {
      padding: 20px 20px 10px;
      // border-left: 5px solid $color-accent;
      margin: 0;
    }
  }

  h4 {
    padding: 20px 20px 10px;
    // border-left: 5px solid $color-accent;
    margin: 0;
  }
  // position: relative; margin-bottom: 0;

  // img {
  //   position: relative; z-index: 1;
  // }

  figcaption {
    @include font-size(18);
    @include title-font(bold);
    background: transparentize($color-accent, .4);
    bottom: 0;
    color: $color-white;
    left: 0;
    line-height: 1em;
    margin: 0;
    padding: 15px;
    position: absolute;
    right: 0;
    text-align: left;
    text-transform: uppercase;
    z-index: 2;

    // .lt_ie9 & {
    //   background: url("../img/bg-trans-blue.png");
    // }
  }

  span {
    // @include sprite(play);
    left: 50%;
    margin-left: -25px;
    margin-top: -30px;
    position: absolute;
    top: 50%;
    z-index: 3;
  }
}

.item__content--image figure.case-img--all{
  figcaption{
    top: 0;
    @include title-font(light);
    @include font-size(36);
    line-height: 1em;
    padding-top: 10px;
    strong{
      @include title-font(bold);
    }
  }
  a:hover, a:active{
    figcaption{
      background: transparentize($color-accent-2, .2);
    }
  }
}

figure.case-img{
  // margin-bottom: 2px;

  .lt_ie9 &, .lt_ie9 & img{
    width: 213px;
    height: 142px;
    overflow: hidden;
  }
}

//	TABELLEN
.table {
  margin-bottom: $margin-bottom;
  //max-width: 100%;
  text-align: left;
  color: $color-grey-dark;
}

  .table__cell {
    padding: 3px 5px;
    border: 1px solid $color-grey-light;
    @include font-size(11);
    line-height: $font-lineheight-alt;
    font-weight: normal;
    text-align: left;
    @include mq($bp-smalltablet, true) {
      padding: 5px;
      @include font-size(13);
    }
  }
  .table__cell--head,
  .table--default .table__cell:first-child,
  .table--row .table__cell:first-child {
    //background-color: darken($color-grey-lighter, 3) !important;
    background-color: $color-accent-2 !important;
    font-weight: bold;
    //color: darken($color-grey-dark, 10%);
    color: $color-white;
  }

  .table__row:nth-child(even) {
    background-color: lighten($color-grey-lighter, 3);
  }

//	YOUTUBE
.video{
  display: block;
  width: 100%;
  margin: 0 0 $margin-bottom;
}
  .video__wrapper{
    position: relative; overflow: hidden;
    width: 100%; height: 0;
    margin: 0 auto; padding: 0 0 56.25%; // 16:9 ratio
    iframe, object, embed{
      position: absolute; top: 0; left: 0;
      width: 100% !important; height: 100% !important;
    }
  }

//	ARTIKELEN
.info{
  position: relative; top: - $margin-bottom / 2;
  margin-bottom: $margin-bottom / 2;
  color: $color-grey;
  @include font-size(12);
  line-height: $font-lineheight;
  span{
    display: block;
    span{
      display: none;
    }
  }
  @include mq($bp-smalltablet, true) {
    @include font-size(14);
    span span{
      display: inline;
    }
  }
}

article{
  margin-bottom: $margin-bottom / 2;
  display: block;
  border-bottom: 1px solid $color-grey-lighter;
  padding-bottom: $margin-bottom / 2;
  &.first{
    border-top: 1px solid $color-grey-lighter;
    padding-top: $margin-bottom / 2;
  }
  &.last{
    margin-bottom: $margin-bottom;
  }
  @include mq($bp-smalltablet, true) {
    margin-bottom: $margin-bottom;
    padding-bottom: 20px;
    &.first{
      padding-top: 20px;
    }
  }
  &.image{
    position: relative;
    .imgurl{
      display: block;
      text-align: center;
      margin-bottom: $margin-bottom / 2;
      //display: none;
      img{
        display: block;
        width: 100%;
        max-width: 100px;
        margin: 0 auto;
      }
    }
    @include mq($bp-mobile, false) {
      padding-left: 120px; min-height: 110px;
      .imgurl{
        position: absolute; left: 0; top: 0;
        max-width: auto; width: 100px; margin: 0;
        display: block;
        img{
          width: 100px; max-width: auto;
        }
      }
      &.first .imgurl{
        top: 20px;
      }
    }

    @include mq($bp-smalltablet, true) {
      padding-left: 120px; min-height: 140px;
      .imgurl{
        position: absolute; left: 0; top: 0;
        width: 100px; max-width: auto; margin: 0;
        display: block;
        img{
          width: 100px; max-width: auto;
        }
      }
      &.first .imgurl{
        top: 20px;
      }
    }
  }

  &.agenda{
    padding-left: 110px !important; min-height: 110px !important;
    position: relative;
    .imgurl, .placeholder{
      position: absolute; left: 0; top: 0;
      max-width: auto; width: 100px !important; margin: 0;

      img{
        display: block;
        width: 100px !important;
        margin: 0 auto;
      }
    }
    .placeholder{
      font-size: 80px;
      padding: 8px 0 12px;
      height: 80px !important;
    }
    &.first .imgurl, &.first .placeholder{
      top: $margin-bottom / 2;
      @include mq($bp-smalltablet, true) {
        top: $margin-bottom;
      }
    }
    .placeholder{
      background: $color-grey-lighter;
      height: 80px;
      text-align: center;
      color: $color-white;
      font-weight: bold;
    }
  }

  h3{
    margin-bottom: .2em;
  }
  p{
    margin: 0;
  }
  .info{
    top: 0;
  }
}

// PAGING
ul.paging{
  float: right; display: inline;
  margin: 0 0 $margin-bottom;
  li{
    float: left;
    display: inline;

    a, span{
      display: block; padding: 0 3px 2px; margin: 0 2px 2px 0;
      text-decoration: none; font-weight: normal;
      .item &{
        @include font-size(12);
        line-height: 1.5em;
        padding: 0 5px;
        background: $color-white;
      }
    }
    a:hover{
      text-decoration: underline;
    }
    span{
      color: $color-grey;
    }
    &.current a{
      padding: 0 8px 2px;
      color: $color-white; background: $color-accent;
      cursor: default;
      text-decoration: none;
      .item &{
        padding: 0 6px;
      }
    }
    &.jump{
      font-weight: normal;
      color: $color-grey; padding: 0 3px;
    }
    &.previous, &.next{
      display: none;
      @include mq($bp-smalltablet, true) {
        display: inline;
        .item &{
          display: none;
        }
      }
    }
  }
}

//	FOTOALBUM
.image-gallery{
  background: #f2f2f2;
  padding: 10px 10px 0 0;
  margin-bottom: $margin-bottom;
  // @include clearfix();
  // &::after{
  // 	clear: none;
  // }
  @include e(list){
    margin: 0; padding: 0;
    // @include clearfix();
    // &::after{
    // 	clear: none;
    // }
  }
  @include e(item){
    float: left; display: inline;
    list-style: none;
    //width: 50%;
    width: 33.33%;
    @include mq($bp-mobile, true){
      width: 33.33%;
    }
    a{
      margin: 0 0 10px 10px;
      display: block;
      background: $color-accent;
      &:hover, &:active{
        img{
          @include opacity(70);
        }
      }
    }
  }
  @include e(image){
    width: 100%;
    display: block;
  }
}
