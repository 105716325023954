// LANGUAGE-MENU
.lang-menu {
  // display: inline;
  // float: right;
  display: none;
  margin: 10px 0 0 0;

  @include mq($bp-smalltablet, false) {
    margin: 10px 0 10px 0;
  }

  @include mq($bp-tablet, true) {
    float: right;
    display: inline;
    margin: 10px 0 10px 0;
  }

  @include mq($bp-desktop, true) {
    margin: 21px 0 15px 0;
  }

  // LANG-MENU__ITEM
  @include e(item) {
    list-style: none;

    @include m(divider) {
      @include title-font();
      @include font-size(14);
      display: none;
      padding: 0 5px;

      @include mq($bp-mobile, true) {
        display: inline;
      }
    }

    @include mq($bp-mobile, true) {
      float: left;
      display: inline;
    }
  }

  // LANG-MENU__LINK
  @include e(link) {
    @include font-size(14);
    @include title-font();
    border: 1px solid $color-grey-lighter;
    color: $color-grey;
    display: block;
    letter-spacing: .05em;
    line-height: 16px;
    margin-bottom: 5px;
    padding: 2px 3px 0;
    text-align: center;
    text-decoration: none;

    &:hover, &:active {
      background: $color-grey-lighter;
    }

    @include mq($bp-mobile, true) {
      border: 0;
      line-height: inherit;
      margin: 0;
      padding: 1px 0 0;
      text-align: left;

      &:hover, &:active {
        background: transparent;
      }
    }

    @include m(selected) {
      color: $color-accent-2;
    }
  }

  ul {
    display: inline-block;
    vertical-align: bottom;
  }

  .divider {
    height: 12px;
    display: inline-block;
    width: 1px;
    background: $color-text;
    margin: 0 5px 0 0;
  }

  .contact__item {
    @include title-font();
    @include font-size(14);
    font-weight: bold;
    position: relative;
    background-color: $color-accent;
    color: $color-white;

    .fa-caret-down {
      padding: 4px 6px 6px;
    }

    &:hover {

      .fa-caret-down {
        background-color: $color-accent;
        color: $color-white;
      }

      .contactsub {
        display: block;
      }
    }
  }

  .contact__link {
    text-transform: uppercase;
    text-decoration: none;
    color: $color-white;
    padding-left: 5px;
  }

  .contactsub {
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 100;

    &__item {

    }

    &__link {
      display: block;
      background-color: $color-accent;
      padding: 3px 5px;
      color: $color-white;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        background-color: $color-hover;
        color: $color-white;
      }
    }
  }
}

.toggle-mobile-menu {
  color: $color-hover;
  position: absolute;
  top: 32px;
  right: 10px;
  font-size: 16px;
  line-height: 42px;
  vertical-align: middle;
  text-decoration: none;
  display: inline-block;

  .fa {
    font-size: 42px;
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 3px;
  }

  @include mq($bp-tablet) {
    display: none;
  }
}

// TOP-MENU
.top-menu {
  display: none;
  clear: both;

  @include mq($bp-tablet, true) {
    display: block;
    text-align: right;
  }

  @include mq($bp-desktop) {
    float: right;
    clear: right;
  }

  @include e(item) {
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 0 10px;

    @include mq($bp-desktop) {
      margin: 0 0 0 10px;
    }
  }

  .top-menu__item--active,
  .top-menu__item--current {
    background-color: $color-accent;

    .top-menu__link {
      color: $color-white;
    }
  }

  @include e(link) {
    @include font-size(16);
    @include title-font();
    color: $color-accent;
    display: block;
    font-weight: bold;
    letter-spacing: .05em;
    line-height: 20px;
    margin-top: 3px;
    padding: 10px 0;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:active {
      color: $color-hover;
    }

    @include mq($bp-smalltablet, false) {
      // margin-bottom: 5px;
    }

    @include mq($bp-tablet, true) {
      @include font-size(18);
    }

    @include mq($bp-desktop, true) {
      line-height: 24px;
      // margin-bottom: 0;
    }

    // @include m(selected) {
    //   background: $color-accent-2;
    //   color: $color-white !important;
    //   // padding: 2px 5px 0;
    // }
  }

  @include e(search) {
    // display: none;

    @include mq($bp-mobile, false) {
      float: right;
      display: inline;
      margin-right: 10px;
    }

    @include mq($bp-smalltablet, false) {
      clear: left;
      float: right;
      display: inline;
      margin-right: 0;
      margin-bottom: 0;
    }

    @include mq($bp-desktop, true) {
      clear: none;
      float: left;
      display: inline;
      margin-right: 0;
    }

    // .search__container {
    //   background: $color-white;
    //   border: 2px solid $color-grey-light;
    //   display: inline-block;
    //   height: 24px;
    //   margin: 0;
    //   overflow: hidden;
    //   position: relative;
    //   width: 148px;

    //   @include mq($bp-smalltablet, true) {
    //     width: 196px;
    //     height: 28px;
    //   }

    //   input {
    //     float: left;
    //     display: inline;
    //     outline: none;
    //     border: none;

    //     &.search__input {
    //       @include font-size(16);
    //       @include title-font();
    //       background: $color-white;
    //       border: 0;
    //       color: $color-grey;
    //       font-family: $font-family;
    //       height: 20px;
    //       line-height: 22px;
    //       margin: 0;
    //       outline: none;
    //       padding: 2px 2px 2px 5px;
    //       text-transform: uppercase;
    //       width: 100%;

    //       @include mq($bp-smalltablet, false) {
    //         height: 24px;
    //         line-height: 27px;
    //       }

    //       @include mq($bp-tablet, true) {
    //         @include font-size(18);
    //         line-height: 29px;
    //       }

    //       @include mq($bp-desktop, true) {
    //         @include font-size(18);
    //         height: 24px;
    //       }

    //       // .ff & {
    //       //   position: relative;
    //       //   margin-top: 4px;
    //       //   -moz-box-sizing: border-box;
    //       //   box-sizing: border-box;
    //       //   overflow: hidden;
    //       // }

    //       // .ie10 &{
    //       //   line-height: 25px;
    //       // }
    //       // .ie9 &{
    //       //   line-height: 25px;
    //       // }
    //       // .lt_ie9 &,{
    //       //   line-height: 24px;
    //       // }
    //     }
    //   }

    //   .search__button{
    //     position: absolute; right: 0; top: 0;
    //     background: $color-white;
    //     border: 0; outline: 0;
    //     width: 28px; height: 22px;
    //     line-height: 20px;
    //     padding: 0; margin: 0;
    //     @include opacity(50);
    //     cursor: pointer;
    //     color: $color-grey;
    //     @include font-size(12);

    //     @include mq($bp-smalltablet, true) {
    //       height: 26px;
    //     }

    //     &:hover,
    //     &:focus {
    //       @include opacity(100);
    //       color: $color-grey;
    //     }

    //     // .ff &{
    //     //   top: 2px;
    //     // }

    //     // .lt_ie9 &{
    //     //   top: 2px;
    //     // }
    //   }
    // }
  }
}

.search {
  display: none;
  background-color: $color-accent;
  box-sizing: border-box;
  color: $color-white;
  position: absolute;
  z-index: 200;

  @include mq($bp-tablet) {
    top: 100%;
    right: 0;
    left: 0;
    padding: 20px;
  }

  &--open,
  &--content {
    display: block;
  }

  &__container {
    display: flex;

    .input {
      flex: 1 1 100%;
    }

    input {
      border: 0;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      padding: 10px;
      width: 100%;
    }

    .button {
      flex: 0 0 auto;
    }

    button {
      background-color: $color-accent-2;
      border: 0;
      top: 0;
      color: $color-white;
      display: block;
      height: 40px;
      margin: 0;
      padding: 0 20px;
      text-align: center;
    }
  }
}

.search--content {
  @include clearfix();
  background-color: rgba($color-accent, .25);
  border-radius: 5px;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  margin-bottom: 20px;
}

.search-results {
  max-width: 700px;
}

.top-menu-submenu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-height: 200px;
  z-index: 9999;

  &--open {
    display: block;
  }

  .submenugroup {
    display: none;

    &--visible {
      display: block;
    }
  }

  .nmenu {
    background-color: $color-hover;
    padding: 30px 75% 30px 0;

    &__item {

    }

    &__link {
      @include title-font(bold);
      font-size: 24px;
      display: block;
      padding: 15px 20px;
      color: $color-white;
      text-decoration: none;
      text-transform: uppercase;

      .fa {
        float: right;
        position: relative;
        top: -3px;
      }
    }

    .nmenu__item--current .nmenu__link {
      background-color: $color-accent;
    }
  }

  .nsubmenu {
    display: none;
    position: absolute;
    left: 25%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $color-accent;
    padding: 30px 0;
    flex-wrap: wrap;
    flex-direction: column;

    &--visible {
      display: flex;
    }

    &__item {
      flex: 0 0 auto;
      width: 50%;
    }

    &__link {
      @include title-font(regular);
      box-sizing: border-box;
      font-size: 24px;
      display: block;
      padding: 10px 20px 10px 10px;
      margin: 5px 0 5px 30px;
      color: $color-white;
      text-decoration: none;
      border-left: 4px solid $color-accent;
      opacity: .8;
      text-transform: uppercase;

      &:hover {
        border-left: 4px solid $color-white;
        opacity: 1;
      }
    }
  }
}


// //MAIN-MENU
// .menu{
//   clear: both;
//   position: relative; z-index: 5;
//   height: 32px; margin: 10px 10px 0;
//   @include mq($bp-smalltablet, false) {
//     margin: 0; height: inherit;
//   }
// }

// .main-menu{
//   //position: absolute; left: 0; right: 0; top: 0;
//   display: block;
//   //padding-top: 32px; height: 0; overflow: hidden;
//   @include mq($bp-smalltablet, false) {
//     position: static;
//     float: right; display: inline; clear: right;
//     margin: 0 20px 0 0; padding: 0;
//     height: inherit; overflow: visible;
//   }
//   @include mq($bp-tablet, true) {
//     margin: 0 40px 0 0;
//   }
//   @include e(item) {
//     float: left; display: block;
//     width: 49%; margin: 0 0 2% 1%;
//     &.odd{
//       margin: 0 1% 2% 0;
//     }
//     @include m(home) {
//       display: block;
//     }
//     @include mq($bp-mobile, false) {
//       width: 49.5%; margin: 0 0 1% .5%;
//       &.odd{
//         margin: 0 .5% 1% 0;
//       }
//     }
//     @include mq($bp-smalltablet, true) {
//       //position: relative;
//       float: left; display: inline; zoom: 1;
//       margin: 0 0 0 12px;
//       width: auto;
//       &.odd{
//         margin: 0 0 0 12px;
//       }
//       @include m(home) {
//         display: none;
//       }
//     }
//     &.hover .main-menu__link{
//       background: $color-accent-2;
//     }
//   }
//   @include e(link) {
//     display: block;
//     line-height: 24px;
//     padding: 5px 10px 2px;
//     background: $color-accent;
//     color: $color-white;
//     text-decoration: none;
//     @include font-size(20);
//     @include title-font(bold);
//     text-transform: uppercase;
//     @include mq($bp-smalltablet, false) {
//       @include font-size(20);
//       line-height: 24px;
//     }

//     @include mq($bp-tablet, true) {
//       @include font-size(26);
//       line-height: 30px;
//       padding: 7px 15px 4px;
//     }

//     &:hover, &:active {
//       color: $color-white;
//       background: $color-accent-2 !important;
//     }

//     @include m(selected) {
//       background: $color-accent-2;
//       //position: absolute; left: 0; right: 0; top: 0;
//       i{
//         position: absolute; right: 10px; top: 0;
//         @include font-size(20);
//         line-height: 24px;
//         display: none !important;

//         &.fa-sort-asc{
//           display: block;

//           .down &{
//             display: none;
//           }
//         }

//         &.fa-sort-desc{
//           display: none;
//           top: 8px;

//           .down &{
//             display: block;
//           }
//         }
//       }

//       @include mq($bp-smalltablet, true) {
//         position: static;

//         i {
//           display: none !important;
//         }
//       }
//     }
//   }
// }

// //MAINMENU SUB
// .main-menu--second{
//   display: none;

//   @include mq($bp-smalltablet, true) {
//     //border-top: 10px solid red;
//     @include clearfix();
//     background: rgba(0,0,0,.7);
//     border-top: 10px solid $color-white;
//     display: block;
//     padding: 10px 0;
//     position: absolute;
//     right: 0;
//     top: 31px;
//     visibility: hidden;
//     width: 100%;
//     z-index: 2;
//   }

//   @include mq($bp-tablet, true) {
//     top: 41px;
//     padding: 20px 0 10px;
//   }

//   @include mq($bp-desktop, true) {
//     width: 926px;
//     padding: 30px 0 10px 40px;
//   }

//   // .lt_ie9 &{
//   //   background: url("../img/bg-trans.png");
//   // }

//   .main-menu__item:hover & {
//     visibility: visible;
//   }

//   @include e(item) {
//     display: inline;
//     float: left;
//     margin: 0 1.5% 20px;
//     padding-top: 18px;
//     width: 30%;

//     @include mq($bp-tablet, false) {
//       width: 22%;
//     }

//     @include mq($bp-desktop, true) {
//       width: 175px;
//       margin: 0 16px 20px;
//     }
//   }

//   @include e(link) {
//     @include font-size(18);
//     @include title-font(bold);
//     color: $color-white;
//     display: block;
//     height: 18px;
//     line-height: 18px;
//     padding: 0 12px;
//     position: relative;
//     text-decoration: none;
//     text-transform: uppercase;

//     &:hover,
//     &:active {
//       color: $color-grey-light;
//     }

//     span {
//       position: absolute;
//       bottom: 0;
//       left: 12px;
//       right: 12px;
//       margin-bottom: 4px;
//     }
//   }
// }

// //MAINMENU SUB-SUB
// .main-menu--third {
//   background: $color-white;
//   border-top: 7px solid $color-accent;
//   display: block;
//   padding: 3px 0 10px;

//   .main-menu--second__item:hover & {
//     border-top-color: $color-accent-2;
//   }

//   @include e(item) {
//     display: block;
//   }

//   @include e(link) {
//     //display: inline;
//     @include font-size(12);
//     color: $color-grey-dark;
//     display: block;
//     font-family: $font-family;
//     line-height: 13px;
//     padding: 4px 12px;
//     text-decoration: none;

//     @include m(selected) {
//       color: $color-accent;
//       text-decoration: underline;
//     }

//     &:hover,
//     &:active {
//       color: $color-accent-2;
//     }
//   }
// }

// SUBMENU IN SIDEBAR
.submenu {
  display: none;

  @include mq($bp-smalltablet, true) {
    display: block;
    // .lt_ie9 &{
    //   position: absolute; left: 15px; top: 34px;
    //   clear: none;
    // }
  }

  @include e(item) {
    display: block;
    list-style: none;
    margin-bottom: 15px;
  }

  @include e(link) {
    @include font-size(15);
    @include title-font(bold);
    color: $color-grey;
    display: block;
    line-height: 15px;
    padding-left: 16px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    @include m(selected) {
      //color: $color-accent-2;
    }

    &.active{
      color: $color-accent;
    }

    span {
      //.fa-minus{display: none;}
      //.fa-plus{display: none;}
      @include font-size(11);
      background: $color-accent-2;
      color: $color-white;
      display: block;
      height: 12px;
      left: 0;
      line-height: 15px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 12px;

      .icon{
        // @include sprite(plus);
        display: inline;
        float: left;
        height: 8px;
        margin: 2px;
        width: 8px;
      }
    }

    &.active span {
      background: $color-accent-2;
      //@include retina-sprite(minus);
      //.fa-minus{display: none;}
      //.fa-plus{display: none;}

      .icon {
        // @include sprite(minus);
      }
    }
  }
}

.submenu-second{
  //@include clearfix();
  margin-bottom: 10px;
  padding-top: 5px;

  @include e(item) {
    clear: both;
    display: block;
    list-style: none;
    margin: 0 0 0 16px;
    padding: 0;
  }

  @include e(link) {
    @include font-size(12);
    color: $color-grey;
    display: block;
    line-height: 14px;
    padding: 0 0 5px;
    text-decoration: none;

    @include m(selected) {
      color: $color-accent;
    }

    @include mq($bp-tablet, true) {
      @include font-size(12);
      line-height: 14px;
    }
  }
}

.submenu-third{
  //@include clearfix();
  //padding-top: 5px;
  margin-bottom: 5px;

  @include e(item) {
    border-left: 1px solid $color-grey-light;
    clear: both;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @include e(link) {
    @include font-size(11);
    color: lighten($color-grey, 25);
    display: block;
    line-height: 14px;
    padding: 2px 0 2px 6px;
    text-decoration: none;

    @include m(selected) {
      color: $color-accent;
    }

    @include mq($bp-tablet, true) {
      @include font-size(11);
      line-height: 14px;
    }
  }
}

// BREADCRUMB
.breadcrumb {
  @include font-size(14);
  @include text-overflow();
  @include title-font();
  color: $color-grey;
  display: none;
  letter-spacing: .05em;
  line-height: 16px;
  margin: 0 0 20px;
  text-transform: uppercase;

  .breadcrumb__item {
    color: $color-grey;
    text-decoration: none;

    &:hover {
      color: $color-grey-dark;
    }
  }

  @include mq($bp-tablet, true) {
    display: block;
  }
}




$mobile-menu-width          : 320px;
$mobile-menu-gutter         : 5px;
$mobile-menu-margin         : 20px;
$mobile-menu-bgcolor        : $color-accent;
$mobile-menu-border         : 1px solid darken($mobile-menu-bgcolor, 5);
$mobile-menu-header-bgcolor : darken($mobile-menu-bgcolor, 10);
$mobile-menu-link-bgcolor   : darken($mobile-menu-bgcolor, 10);
$mobile-menu-text           : $color-white;
$mobile-menu-padding        : 10px;
$mobile-menu-button-width   : 30px;

.menu-toggle {
  position: absolute;
  top: 15px;
  left: 15px;
  line-height: 24px;
  color: $color-white;
  font-size: 14px;
  text-decoration: none;
  vertical-align: middle;
  z-index: 2;

  &:hover {
    color: $color-white;
  }

  .fa {
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}

.mobile {
  background: $mobile-menu-bgcolor;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  transform: translate(-100%, 0);
  transition: transform .3s cubic-bezier(.05, .45, .35, .95);
  width: $mobile-menu-width;
  z-index: -1;

  @include mq($bp-tablet, true) {
    display: none;
  }

  .show-menu & {
    transform: translate(0, 0);
    z-index: 2000;
  }

  &__wrapper {
    height: calc(100% - 50px);
    margin: 35px auto 0;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    width: $mobile-menu-width - (2 * $mobile-menu-gutter);

    .no-csscalc & {
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .toggle-menu {
    background: $mobile-menu-link-bgcolor;
    position: absolute;
    right: 20px;
    top: 10px;
  }

  &-menu {
    list-style: none;
    transition: all .25s cubic-bezier(.05, .45, .35, .95);
    // breedte voor alle (sub)menu's wordt berekend met jquery

    &.close {
      margin-left: -100%;
    }

    &--main {
      border-top: $mobile-menu-border;
      margin-top: $mobile-menu-margin;
    }

    &--sub {
      left: 100%;
      position: absolute;
      top: 0;
      min-height: 100vh;
      z-index: 1;
      background-color: $mobile-menu-bgcolor;

      li:last-child {
        margin-bottom: $mobile-menu-margin;
      }

      &.show {
        // height: auto;
        left: 0;
        z-index: 2;

        ul {
          left: 100%;
        }
      }

      &.close {
        left: 0%;

        .show {
          left: 100%;
        }
      }
    }

    &__item {
      clear: left;

      &--header a {
        background: $mobile-menu-header-bgcolor;
        color: $mobile-menu-text;
        margin-top: $mobile-menu-margin;
        padding: $mobile-menu-padding ($mobile-menu-padding + $mobile-menu-button-width);
        text-align: center;

        span {
          bottom: 0;
          left: 0;
          position: absolute;
          text-align: center;
          top: 0;

          i {
            left: 4px;
            position: absolute;
            top: 4px;
            bottom: 4px;
            background-color: rgba($color-white, .05);
            height: 28px;
            width: 28px;
            text-align: center;
            color: $color-white;
            line-height: 28px;
          }
        }
      }
    }

    &__link {
      @include font-size(14);
      border-bottom: $mobile-menu-border;
      background-color: $mobile-menu-bgcolor;
      color: $mobile-menu-text;
      display: block;
      // font-family: $font-default;
      font-weight: bold;
      line-height: 1.2;
      padding: $mobile-menu-padding;
      position: relative;
      text-decoration: none;

      &:hover {
        color: $color-white;
      }

      &--parent {
        padding-right: $mobile-menu-padding + $mobile-menu-button-width;

        .open-sub {
          background: $mobile-menu-link-bgcolor;
          // border-radius: 50%;
          height: $mobile-menu-button-width;
          margin-top: -($mobile-menu-button-width / 2);
          position: absolute;
          right: 0;
          text-align: center;
          top: 50%;
          width: $mobile-menu-button-width;

          i {
            color: $mobile-menu-text;
            left: 50%;
            margin-left: -4px;
            margin-top: -7px;
            position: absolute;
            top: 50%;
          }
        }
      }
    }
  }
}
