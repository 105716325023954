html {
	font-size : 62.5%; // LATEN STAAN - NIET AANPASSEN
	height: 100%; overflow-y: scroll;
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : rgba(0,0,0,0.1);
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%;
}

body {
  @include base-font();
  @include base-font-size(13);
  background-color: $color-body;
  color: $color-text;
  height: 100%;

  .ie9 & {
    //float: left; display: block;
    //height: auto;
    width: 100%;
  }
}

//	RAAMWERKEN

.site {
  // max-width: 1024px;
  // min-height: 100%;
  background: $color-white;
  margin: 0 auto;
  min-width: 320px;
  width: 100%;

  // .lt_ie9 & {
	// 	width: 1024px;
	// }
}

.container {
  @include clearfix();
  clear: both;
  display: block;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  max-width: 1080px;
  margin: 0 auto 40px;

  @include mq($bp-smalltablet) {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .home & {
    background: #ececec;
    padding-top: 40px;
    max-width: none;
  }
}

.content {
  @include clearfix();
  display: block;
  padding: 10px 0 20px;
  position: relative;
  margin: 0 auto;
  flex: 0 0 100%;

  @include mq($bp-smalltablet) {
    flex: 1 1 100%;
  }

  .home & {
    // flex: none;
    max-width: 1080px;
  }

  .home & > h4 {
    display: block;
    font-size: 42px;
    text-align: center;
    margin-bottom: 40px;
  }
}

.sidebar {
  flex: 0 0 100%;
  padding: 10px 0;

  @include mq($bp-smalltablet) {
    flex: 0 0 100px;
    padding-top: 46px;
    padding-right: 20px;

    select {
      display: none;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .item {
    // @include clearfix();
    background-color: $color-white;
    box-sizing: border-box;
    flex: 0 0 100%;
    margin-bottom: 30px;
		overflow: hidden;
    position: relative;
		border: 1px solid $color-grey-light;

    @include mq($bp-mobile) {
      flex: 0 0 49%;
    }

    @include mq($bp-tablet) {
      flex: 0 0 31.33%;
    }

		.border {
	    left: 0;
	    overflow: hiddden;
	    position: absolute;
	    bottom: 0;
	    z-index: 10;

	    &__section {
	      width: 8px;

				&--one {
					background-color: #d8d8d8;
					height: 10px;
				}

				&--two {
					background-color: $color-accent;
					height: 20px;
				}

				&--three {
					background-color: $color-accent-2;
					height: 15px;
				}

				.home & {

		      &--one {
		        height: 40px;
		      }

		      &--two {
		        height: 105px;
		      }

		      &--three {
		        height: 55px;
		      }
				}
	    }
	  }

    figure,
    img {
      width: 100% !important;
    }

    .home & {
      padding-bottom: 40px;
			cursor: pointer;

      &:after {
        content: '';
        width: 20px;
        height: 33px;
        background:url(../images/arrow.svg);
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }

	.item__content {
		position: relative;
		z-index: 100;

    p {
      text-align: justify;
    }
	}

  .item--image {

    &:before {
      content: '';
      left: 0;
      top: 0;
      width: 8px;
      bottom: 0;
      background-color: #d8d8d8;
      position: absolute;
    }
  }
}

// .item1 {
//   display: inline-block;
//   width: 100%;

//   .item__content {
// 		position: relative;
// 		background: #f2f2f2;
// 		margin: 0 10px 0 16px;
// 		padding: 10px 10px 0;
// 		border-bottom: 6px solid $color-accent;

//     &:hover {
// 			border-bottom: 6px solid $color-accent-2;
// 		}

// 		@include m(image) {
// 			padding: 0;
// 			position: relative;
// 			background: $color-white;
// 		}

//     @include m(case) {
// 			padding: 0;
// 			position: relative;
// 			background: $color-white;
// 		}

//     &.item__content--link {
// 			cursor: pointer;

//       &:hover p {
// 				color: $color-accent;
// 			}
// 		}
// 	}

//   @include mq($bp-mobile, true) {
//     display: inline;
//     float: left;
//     width: 50%;

//   	@include e(content) {
// 			margin: 0 10px 0 0;
// 		}
// 	}

//   @include mq($bp-smalltablet, true) {

//     @include e(content) {
// 			margin: 0 10px;
// 		}
// 	}

//   @include mq($bp-tablet, false) {

//     @include e(content) {
// 			margin: 0 0 0 15px;
// 		}
// 	}

//   @include mq($bp-desktop, true) {
// 		width: 33.33%;

//     @include e(content) {
// 			margin: 0 0 0 20px;
// 		}
// 	}
// }
