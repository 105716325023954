.footer {
  @include clearfix();
  clear: both;
  display: block;
  margin: 0 auto;
  max-width: 1060px;
  padding: 0 10px;
  position: relative;
  z-index: 1;

  .border {
    display: none;
  }

  @include mq($bp-smalltablet, true) {
    // padding: 0 0 0 34px;
    //margin-left: 164px;
    // margin-right: 0;
  }

  @include mq($bp-tablet, false) {
    // padding: 0 15px 0 34px;
    //margin-left: 200px;
    // margin-right: 20px;
    // max-width: 908px;
  }

  @include mq($bp-desktop, true) {
    // padding: 0 40px 0 20px;
    // border-right: 12px solid $color-accent;
    // margin-left: 220px;
    // margin-right: 36px;
    // max-width: 100%;
  }
}

.footer__block {
  float: left;
  margin-bottom: 40px;
  width: 50%;

  @include mq($bp-smalltablet, true) {
    width: 25%;
  }

  @include m(third) {
    clear: left;

    @include mq($bp-smalltablet, true) {
      clear: none;
    }
  }
}

.footer__menu {

  @include e(item) {
    @include font-size(11);
    list-style: none;

    @include mq($bp-mobile, true) {
      @include font-size(12);
    }
  }

  @include e(link) {
    text-decoration: none;
    color: darken($color-grey-light, 10);

    &:hover, &:active {
      color: $color-accent-2;
    }
  }
}

.footer-menublock {
  border-top: 1px solid darken($color-grey-lighter, 10);
  clear: both;
  display: block;
  margin: 0 10px 0 0;

  @include mq($bp-smalltablet, true) {
    margin-right: 20px;
  }
}

.footer-menu {
  display: block;
  text-align: center;
  margin: 5px 0 10px;

  @include mq($bp-mobile, true) {
    text-align: right;
  }

  @include e(item) {
    @include font-size(11);
    display: inline;
    list-style: none;
    margin: 0 5px;

    @include mq($bp-mobile, true) {
      @include font-size(12);
      margin: 0 0 0 10px;
    }
  }

  @include e(link) {
    text-decoration: none;
    color: darken($color-grey-light, 10);

    &:hover, &:active {
      color: $color-accent-2;
    }
  }
}
