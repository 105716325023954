//	FORMULIER
input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

.content form {
	float: left;
  display: inline;
}

form{
	margin: 0;
  padding: 0;
	width: 100%;
}

.formulier {
	margin-bottom: $margin-bottom;
	overflow: hidden;

	.clearfix {
		margin-bottom: $margin-bottom / 2; zoom: 1;
		&:before, &:after {
			display: table;
			content: "";
			zoom: 1;
		}
		&:after {
			clear: both;
		}
		&.nomargin{
			margin-bottom: 0;
		}
	}

	p{
		margin-bottom: 0;
	}

	label{
		float: left; display: inline;
		width: 100%; padding-top: 0; margin-bottom: 3px;
		line-height: $font-lineheight-alt;
		font-family: $font-family;
		color: $color-grey-darker;
		text-align: left;
		//@include font-size(13);

		.required{
			@include font-size(18);
			color: $color-status-error;
		}
	}

	.input{
		clear: left;
		margin-left: 0;

		.inputs-list {
			margin: 0 0 5px;
			width: 100%;
			li{
				display: block;
				padding: 0;
				width: 100%;
			}
			label {
				display: block;
				float: none;
				width: auto;
				padding: 0 !important;
				margin-left: 20px;
				line-height: 18px;
				text-align: left !important;
				white-space: normal;
				width: 92% !important;
			}
			&:first-child{
				padding-top: 6px;
			}
			input[type=radio], input[type=checkbox] {
				margin-bottom: 0;
				margin-left: -20px;
				float: left;
			}
		}
	}

	input, select, textarea{
		display: inline-block;
		@include font-size();
		font-family: $font-family;
		font-weight: normal;
		line-height: normal;
		width: 97%; height: 18px; padding: 4px 1%;
		color: $color-grey-dark;
    border: 1px solid $color-grey-light;
		// @include rounded(3px);
	}

	input, textarea{

		&:focus {
			outline: 0;
			border-color: lighten($color-grey, 10%);
		}

    &.mini { width: 50px !important; }
		&.small { width: 80px !important; }
		&.placeholder {
			color: $color-grey-light;
			font-weight: normal;
		}
	}

	textarea {
		height: auto; overflow-y: auto;
		resize: vertical;
		width: 97%;
		height: 140px; max-height: 400px;
	}

	select {
		height: 27px;
		*height: auto;
		line-height: 27px;
		*margin-top: 4px;
		padding: initial;
    width: 100%;

		&.mini {width: 60px !important;}
		&.small {width: 90px !important;}

    &:focus{
			@include box-shadow(none);
			outline: 1px dotted $color-grey-dark;
		}
	}

	input{

    &[type=checkbox], &[type=radio] {
			cursor: pointer;
			width: auto !important; height: auto !important;
			padding: 0; margin: 3px 0;
			*margin-top: 0;
			line-height: normal;
			border: none;
		}

    .ie9 &[type=checkbox] {
			position: relative; top: -1px;
		}

		&[type=file] {
			background-color: $color-white;
			padding: initial;
			border: initial;
			line-height: initial;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;

    	&:focus {
				@include box-shadow(none);
				outline: 1px dotted $color-grey-dark;
			}
		}
	}

	button {
		-webkit-font-smoothing: antialiased;
		//background: $oranje;
		border: 0;
		cursor: pointer;
		margin: 0;
		top: 5px;
    outline: 0;
	}

	//ERROR
	.clearfix.error {

    label,
    input,
    textarea{
			color: $color-status-error;
		}

		input,
    textarea,
    select {
			border-color: $color-status-error;

      &:focus {
				@include box-shadow(0 0 6px lighten($color-status-error,25%));
			}
		}
	}

	@include mq($bp-tablet, false) {

    label {
			@include font-size(14);
		}

    .content & {

      label {
				padding-top: 6px;
				margin-bottom: 0;
				width: 30%;
				text-align: right;
			}

      .input {
				clear: none;
				margin-left: 32%;
			}

			p {
				margin-left: 32%;
			}

			input {
				width: 70%;
			}

      select {
				width: 72%;
			}

      textarea {
				height: 200px; width: 90%;
			}

      button {
				margin-left: 32%;
			}
		}
	}

	.lt_ie9 .content & {

		label {
			padding-top: 6px;
			margin-bottom: 0;
			width: 30%;
			text-align: right;
		}

    .input{
			clear: none;
			margin-left: 32%;
		}

		input{
			width: 70%;
		}

    select{
			width: 72%;
		}

    textarea{
			height: 200px;
      width: 90%;
		}

    button{
			margin-left: 32%;
		}
	}


	.newsletter & {
    margin-bottom: 40px;

		.clearfix {
			margin-bottom: 0;
		}

		.default {
			float: left;
      display: inline;
			margin-right: 10px;
			width: 70%;

			@include mq($bp-smalltablet, false) {
				width: 48%;
				margin-right: 20px;
			}

      @include mq($bp-desktop, true) {
				width: 318px;
				margin-right: 20px;
			}
		}

    .actions {
			float: left;
      display: inline;
		}

		input {
			width: 98%;
		}

    button {
			top: 0;
			margin-left: 0;
			padding: 5px 10px 3px;
		}
	}
}

:-moz-placeholder {color: $color-grey-light;}
::-moz-placeholder {color: $color-grey-light;}
::-webkit-input-placeholder {color: $color-grey-light;}
