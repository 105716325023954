.top-space {
  display: none;
}

.header {
  @include clearfix();
  background: $color-white;
  display: block;
  margin: 0 auto;
  max-width: 1060px;
  padding: 0 10px 0;
  position: relative;

  // @include mq($bp-tablet, true) {
  //   padding-bottom: 0;
  // }

  // @include mq($bp-desktop, true) {
  //   padding-top: 0;
  // }

  // .lt_ie9 & {

  //   &:before,
	// 	&:after {
	// 		border: 0 !important;
	// 	}
	// }
}

.header-logo {
  // @include sprite(logo-2015);
  display: block;
  float: left;
  height: 61px;
  overflow: hidden;
  margin: 23px 0;

  img {
    height: 61px;
  }

  // @include mq($bp-tablet, true) {
  //   margin-left: 40px;
  //   // position: relative;
  //   // top: -10px;
  // }
}

.header-holder {
  position: relative;
  overflow: hidden;

  .nav {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    @include mq($bp-tablet) {
      display: block;
    }

    .next,
    .prev {
      background: $color-white;
      color: $color-accent;
      display: block;
      font-size: 18px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      width: 42px;

      &:hover {
        background-color: $color-hover;
        color: $color-white;
      }

      @include mq($bp-desktop) {
        font-size: 24px;
        width: 64px;
        height: 64px;
        line-height: 64px;
      }
    }

    .next {
      background: $color-accent;
      color: $color-white;
    }
  }

  .border {
    left: 0;
    overflow: hiddden;
    position: absolute;
    top: 0;
    z-index: 10;

    &__section {
      width: 14px;

      &--one {
        background-color: #d8d8d8;
        height: 50px;
      }

      &--two {
        background-color: $color-accent;
        height: 150px;
      }

      &--three {
        background-color: $color-accent-2;
        height: 300px;
      }

      &--four {
        display: none;
        background-color: #d8d8d8;
        height: 300px;
      }
    }
  }
}

.header-image {
  display: block;
  clear: both;
  overflow: hidden;
  position: relative;
  min-height: 300px;

  @include mq($bp-tablet) {
    min-height: 500px;
  }

  .standard & {
    min-height: 300px;
    // margin-bottom: 5px;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50% 50%;
  }

  &__content {
    max-width: 1060px;
    margin: 50px auto 0;
    position: relative;
    z-index: 10;
  }

  &__title {
    display: inline-block;
		background: transparentize($color-accent, .4);

    h2 {
      @include font-size(40);
      color: $color-white;
      display: inline-block;
      // line-height: 40px;
      line-height: 1;
      padding: 20px 20px 0;
      text-transform: uppercase;

      @include mq($bp-smalltablet, false) {
        padding: 12px 20px 0;
      }

      @include mq($bp-tablet, false) {
        @include font-size(48);
        // line-height: 48px;
        padding-top: 20px;
      }

      @include mq($bp-desktop, true) {
        // @include font-size(64);
        // line-height: 64px;
        padding-top: 28px;

        .home & {
          padding-bottom: 0; // 12px;
          padding-top: 24px; // 36px;
        }
      }

      strong {
        font-weight: bold;
      }

      .standard & {
        @include text-overflow();
        padding-bottom: 200px;
      }

      small {
        font-size: 32px;
        line-height: 32px;
        font-weight: bold;
      }
    }
  }

  img {
    display: block;
    margin: 0;
    width: 100%;
  }
}
