//	MEDIA QUERIES
//	zie voor mogelijkheden: http://alwaystwisted.com/post.php?s=2013-04-01-my-media-query-mixin

@mixin mq($point, $IE9: false, $query1: min, $query2: width) {
	@if $IE9 == true{
		.lt_ie9 & {
			@content;
		}
		@media screen and (#{$query1}-#{$query2}: $point+px) {
			@content;
		}
	}
	@else {
		@media screen and (#{$query1}-#{$query2}: $point+px) {
			@content;
		}
	}
}

@mixin orient($orientation: landscape) {
	@media (orientation : $orientation) {
		@content;
	}
}

@mixin retina() {
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
		@content;
	}
}

//	BEM

//elements get appended with "__" and the $name
@mixin e($name) {
	@at-root &__#{$name} {
		@content;
	}
}

//modifiers get appended with "--" and the $name
@mixin m($name) {
	@at-root &--#{$name} {
		@content;
	}
}

//	TYPOGRAFIE

// Font-size
@mixin font-size($font-family-size: 14) {
	font-size : #{$font-family-size}px;
	font-size : #{$font-family-size / 10}rem;
}

// Font
@mixin base-font(){
	font-family: $font-family;
	line-height: $font-lineheight;
}

// Title
@mixin title-font(){
	font-family: $font-title;
	line-height: $font-lineheight-title;
	font-weight: bold;
	// text-transform: uppercase;
}

// Base-font-size
@mixin base-font-size($size: 13){
	@include font-size(round($size));
	@include mq($bp-smalltablet, false) {
		@include font-size(round($size + 1));
	}
	@include mq($bp-tablet, true) {
		@include font-size(round($size + 2));
	}
}

// Title-font-size
@mixin title-font-size($size: 24){
	@include font-size(round($size));
	@include mq($bp-smalltablet, false) {
		@include font-size(round($size * 1.25));
	}
	@include mq($bp-desktop, true) {
		@include font-size(round($size * 1.5));
	}
}

// Title
@mixin title-font($weight: ""){
	@if $weight == "light" {
		font-family: "ff-din-web-condensed",sans-serif;
		font-style: normal;
		font-weight: 300;
	} @else if $weight == "regular" {
		font-family: "ff-din-web-condensed",sans-serif;
		font-style: normal;
		font-weight: 400;
	} @else if $weight == "bold" {
		font-family: "ff-din-web-condensed",sans-serif;
		font-style: normal;
		font-weight: 700;
	} @else {
		font-family: "ff-din-web-condensed",sans-serif;
		font-style: normal;
		font-weight: 400;
	}
}

//	POPULAIR

// Text-overflow
@mixin text-overflow() {
	overflow : hidden;
	text-overflow : ellipsis;
	white-space : nowrap;
}

// Opacity
@mixin opacity($value: 80) {
	zoom: 1;  filter: alpha(opacity=$value);
	opacity: #{$value / 100};
}

// Clearfix
@mixin clearfix() {
	& {
		*zoom: 1;
	}
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

//	GRADIENTS

// Gradient Horizontaal
@mixin horizontal($startColor : $color-white, $endColor : $color-grey-lighter) {
	background-color: $endColor;
	background-image  : -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
	background-image  : -webkit-linear-gradient(left, $startColor, $endColor); // Safari 5.1+, Chrome 10+
	background-image  : -moz-linear-gradient(left, $startColor, $endColor);  // FF 3.6+
	background-image  : -ms-linear-gradient(left, $startColor, $endColor); // IE10
	background-image  : -o-linear-gradient(left, $startColor, $endColor); // Opera 11.10
	background-image  : linear-gradient(left, $startColor, $endColor); // W3C
	background-repeat : repeat-x;
}

// Gradient Verticaal
@mixin vertical($startColor : $color-white, $endColor:  $color-grey-lighter) {
	background-image  : -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
	background-image  : -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
	background-color  : $endColor;
	background-image  : -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
	background-image  : -ms-linear-gradient(top, $startColor, $endColor); // IE10
	background-image  : -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
	background-image  : linear-gradient(top, $startColor, $endColor); // W3C
	background-repeat : repeat-x;
}

// Gradient Diagonaal
@mixin directional($startColor : $color-white, $endColor : $color-grey-lighter, $deg : 45deg) {
	background-color  : $endColor;
	background-image  : -moz-linear-gradient($deg, $startColor, $endColor); // FF 3.6+
	background-image  : -ms-linear-gradient($deg, $startColor, $endColor); // IE10
	background-image  : -webkit-linear-gradient($deg, $startColor, $endColor); // Safari 5.1+, Chrome 10+
	background-image  : -o-linear-gradient($deg, $startColor, $endColor); // Opera 11.10
	background-image  : linear-gradient($deg, $startColor, $endColor); // W3C
	background-repeat : repeat-x;
}

//	BORDERS

// Borders
@mixin borders($top-color: #eee, $right-color: #eee, $bottom-color: #eee, $left-color: #eee) {
	border-top : solid 1px $top-color;
	border-left : solid 1px $left-color;
	border-right : solid 1px $right-color;
	border-bottom : solid 1px $bottom-color;
}

// Rounded;
@mixin rounded($radius:4px) {
	-webkit-border-radius : $radius;
	-moz-border-radius : $radius;
	border-radius : $radius;
	background-clip: padding-box;
}

// Border-radius
@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	-webkit-border-top-right-radius : $topright;
	-webkit-border-bottom-right-radius : $bottomright;
	-webkit-border-bottom-left-radius : $bottomleft;
	-webkit-border-top-left-radius : $topleft;
	-moz-border-radius-topright : $topright;
	-moz-border-radius-bottomright : $bottomright;
	-moz-border-radius-bottomleft : $bottomleft;
	-moz-border-radius-topleft : $topleft;
	border-top-right-radius : $topright;
	border-bottom-right-radius : $bottomright;
	border-bottom-left-radius : $bottomleft;
	border-top-left-radius : $topleft;
	-webkit-background-clip : padding-box;
	-moz-background-clip : padding;
	background-clip : padding-box;
}

// Single border-radius
@mixin border-top-radius($radius) {
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-right-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-left-radius($radius) {
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

//	SCHADUW

// Box-shadow
@mixin box-shadow($shadow: 0 1px 3px rgba(0,0,0,.25)) {
	-webkit-box-shadow : $shadow;
	-moz-box-shadow : $shadow;
	box-shadow : $shadow;
}

// Drop-shadow
@mixin drop-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $alpha: 0.1) {
	-webkit-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
	-moz-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
	box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
}

//	EFFECTEN

// Transition
@mixin transition($property, $duration, $function) {
	-o-transition: $property #{$duration + "s"} $function;
	-moz-transition: $property #{$duration + "s"} $function;
	-webkit-transition: $property #{$duration + "s"} $function;
	transition: $property #{$duration + "s"} $function;
}

// Transition-Delay
@mixin delay($delay) {
	-o-transition-delay: #{$delay + "s"};
	-moz-transition-delay: #{$delay + "s"};
	-webkit-transition-delay: #{$delay + "s"};
	transition-delay: #{$delay + "s"};
}

// Translate
@mixin translate($x: 0, $y: 0) {
	-webkit-transform : translate($x, $y);
	-moz-transform : translate($x, $y);
	-ms-transform : translate($x, $y);
	-o-transform : translate($x, $y);
	transform : translate($x, $y);
}

// Translate3D
@mixin translate3d($x: 0, $y: 0, $z: 0) {
	-webkit-transform : translate($x, $y, $z);
	-moz-transform : translate($x, $y, $z);
	-ms-transform : translate($x, $y, $z);
	-o-transform : translate($x, $y, $z);
	transform : translate($x, $y, $z);
}

// Rotate
@mixin rotate($deg) {
	-webkit-transform : rotate($deg);
	-moz-transform : rotate($deg);
	-ms-transform : rotate($deg);
	-o-transform : rotate($deg);
	transform : rotate($deg);
}

// Scale
@mixin scale($ratio) {
	-webkit-transform : scale($ratio);
	-moz-transform : scale($ratio);
	-ms-transform : scale($ratio);
	-o-transform : scale($ratio);
	transform : scale($ratio);
}

// Skew
@mixin skew($x: 0, $y: 0) {
	-webkit-transform : skew($x, $y);
	-moz-transform : skew($x, $y);
	-ms-transform : skew($x, $y);
	-o-transform : skew($x, $y);
	transform : skew($x, $y);
}

//	OVERIGE

// Background-size
@mixin background-size($size) {
	-webkit-background-size : $size;
	-moz-background-size : $size;
	-o-background-size : $size;
	background-size : $size;
}

// Background-clip
@mixin background-clip($clip) {
	-webkit-background-clip : $clip;
	-moz-background-clip : $clip;
	background-clip : $clip;
}

// Box-sizing
@mixin box-sizing($boxsize: border-box) {
	-webkit-box-sizing : $boxsize;
	-moz-box-sizing : $boxsize;
	-ms-box-sizing : $boxsize;
	box-sizing : $boxsize;
}

// userselect (all, element, none, text)
@mixin user-select($select) {
	-webkit-user-select : $select;
	-moz-user-select : $select;
	-o-user-select : $select;
	user-select : $select;
}
