// SPRITE
// $sprites 			: sprite-map("sprite/*.png", $spacing: 10px);
// $sprites-2x 		: sprite-map("sprite@2x/*.png", $spacing: 20px);

// SFEER
$color-body: 		#ececec;
$color-text: 		rgb(71,63,63);

$color-accent: 		#21659f;
$color-accent-2: 	#009fe1;
$color-hover: 		darken($color-accent, 10);
$color-hover-2: 	darken($color-accent-2, 10);

$color-blue: 		$color-accent;
$color-blue-light: 	$color-accent-2;

// BREAKPOINTS (MOBILE FIRST)
$bp-mobile			: 480;  // MOBIEL (LANDSCAPE)
$bp-smalltablet		: 600;  // KLEINE TABLETS
$bp-tablet			: 768;  // IPAD
$bp-desktop			: 1024; // DESKTOP
$bp-widescreen		: 1280; // WIDESCREEN

// AFMETINGEN
$gutter				: 20px; // TUSSENRUIMTE
$margin-bottom		: 20px; // MARGIN TUSSEN LAYOUTMODELLEN

// STANDAARD KLEUREN
$color-black		: rgb(0,0,0);
$color-grey-darker 	: lighten($color-black, 10%);
$color-grey-dark 	: lighten($color-black, 25%);
$color-grey 		: #626262;
$color-grey-light 	: #d8d8d8;
$color-grey-lighter : #ececec;
$color-white 		: rgb(255,255,255);

$color-social-twitter	: #35ccff;
$color-social-facebook	: #03539e;
$color-social-linkedin	: #217bc1;

$color-status-error		: #E41B13; // ROOD
$color-status-ok		: #7AB317; // GROEN
$color-status-info		: #FFF2AF; // BOTERGEEL

// TYPOGRAFIE
$font-lineheight		: 1.5em;
$font-lineheight-alt	: 1.1em; // WORDT OA GEBRUIKT VOOR ONDERSCHRIFT, TABELLEN, BUTTONS, OPSOMMINGEN
$font-lineheight-title	: 1.2em; // WORDT GEBRUIKT VOOR TITELS

$font-family			: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-title				: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-code				: Monaco, Consolas, "Lucida Console", monospace;

// VEELGEBRUIKTE FONT-STACKS:
// Arial	 		: Arial, "Helvetica Neue", Helvetica, sans-serif;
// Courier New		: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
// Georgia			: Georgia, Times, "Times New Roman", serif;
// Helvetica		: "Helvetica Neue", Helvetica, Arial, sans-serif;
// Lucida Grande	: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
// Tahoma			: Tahoma, Verdana, Segoe, sans-serif;
// Times New Roman	: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
// Trebuchet MS		: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
// Verdana			: Verdana, Geneva, sans-serif;
